"use strict";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {addClass, find, findIn, hasClass, findAllIn, removeClass, on} from '@elements/dom-utils';
import {findAll} from "./elements-packages/dom-utils";

export function init () {
    onFind('.js-account-overlay__open', function(toggleOverlay){
        let overlay = find('.js-account-overlay');
        if(overlay){
            let close = find('.js-account-overlay__close');

            on('click', function () {
                if(!hasClass('is-open', overlay)){
                    addClass('is-open', overlay);
                }
            }, toggleOverlay);

            close.addEventListener('click', function (evt){
                evt.preventDefault();
                removeClass('is-open', overlay);
            })
        }
    })
}
