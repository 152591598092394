"use strict";
import {find, addClass, removeClass, on} from '@elements/dom-utils';

export function init () {
    if(matchMedia('(max-width: 767px)').matches) {
        /*don't init on mobile or if the page is scaled down*/
        return;
    }

    let navbar = find('.js-affix-navbar'),
        navbarContainer = find('.js-affix-navbar-container'),
        lastScrollTop,
        offset,
        didScroll;

    function scrollHandler() {
        let scrollTopPosition = window.scrollY;

        if (lastScrollTop > scrollTopPosition) {
            /*scroll up*/
            if(window.scrollY <= offset) {
                removeClass('affix', navbar);
                removeClass('is-affix', navbarContainer);
            }
        } else if (lastScrollTop < scrollTopPosition) {
            /*scroll down*/
            if(window.scrollY >= offset) {
                addClass('affix', navbar);
                addClass('is-affix', navbarContainer);
            }
        }

        lastScrollTop = scrollTopPosition;
    }

    if (navbar) {
        offset = navbarContainer.getBoundingClientRect().top + window.scrollY + 25;

        if(window.scrollY > offset) {
            addClass('affix', navbar);
            addClass('is-affix', navbarContainer);
        }

        on('scroll', function () {
            didScroll = true;
        }, window)

        requestAnimationFrame(function animationFrameHandler() {
            if (didScroll) {
                scrollHandler();

                didScroll = false;
            }
            requestAnimationFrame(animationFrameHandler);
        });
    }
}
