'use strict';

import {find, findAllIn, closest, findIn, hasClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from '@elements/data-set-utils';

let defaultOptions = {
    scrollOffset: 75,
    initialPosition: false
};
let defaultSelectors = {
    base: '.js-smoothscroll',
    link: '.js-scroll-to',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createSmoothScroll(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

}

export function createSmoothScroll(smoothScroll, options = defaultOptions, selectors = defaultSelectors) {
    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('smoothscroll', smoothScroll)
    };


    const anchors = findAllIn(defaultSelectors.link, smoothScroll);

    if (elementOptions.initialPosition){
        setTimeout(function() {
            setInitialPosition(smoothScroll, options, selectors);
        }, 1);
    }

    anchors.forEach(element => {
        element.addEventListener('click', function (evt) {
            evt.preventDefault();
            const anchor = element;
            let target;
            if(element.hash){
                target =  findIn(element.hash, smoothScroll) || find(element.hash);
            }

            if (anchor.getAttribute('data-scroll-to')) {
                target = find(anchor.getAttribute('data-scroll-to'));
            }

            let offset = anchor.dataset.scrollOffset ?  anchor.dataset.scrollOffset : elementOptions.scrollOffset;

            if (!target) {
                console.warn(`Could not find target ${element.hash} for element `, element);
                return;
            }

            let inlineNavContainer = closest('.js-inline-nav-container', anchor);

            if( inlineNavContainer && hasClass('is-open', inlineNavContainer)) {
                removeClass('is-open', closest('.js-inline-nav-container', anchor));
            }

            smoothscroll(element, target, offset, selectors, anchor.offsetTop, elementOptions.overlay ? smoothScroll : false)
        })
    });
}

export function setOffset(offset) {
    defaultOptions.scrollOffset = offset;
}

export function smoothscroll(el, target, offset = defaultOptions.scrollOffset, selectors, anchorOffset, overlay = false) {
    scrollTo(el, target, offset, anchorOffset, overlay)
}

function scrollTo(el, target, offset, anchorOffset, overlay) {
    let scrollOffset = target.offsetTop - offset;

    const fixedOffset = scrollOffset.toFixed(),
        onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                target.focus();
                if(el){
                    history.replaceState({}, '', el.hash);
                }
                if (target === document.activeElement) {
                    return false;
                } else {
                    target.focus();
                }
            }
        };

    window.addEventListener('scroll', onScroll);

    if (overlay) {
        console.log(el)
        overlay.scrollTo({
            top: scrollOffset,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo({
            top: scrollOffset,
            behavior: 'smooth'
        });
    }
}

function setInitialPosition(scope, options, selectors) {
    let hash = window.location.hash;
    if(hash) {
        const
            pattern = new RegExp(hash),
            anchors = findAllIn(selectors.link, scope),
            target = find(hash);

        let offset = options.scrollOffset;

        anchors.forEach((el) => {
            offset = pattern.test(el.getAttribute('href')) && el.getAttribute('data-'+selectors.dataOffset) ? el.getAttribute('data-'+selectors.dataOffset) : offset;
        });

        smoothscroll(this, target, offset, defaultSelectors, 0)
    }
}
