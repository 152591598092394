"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on, is} from '@elements/dom-utils';

export function init () {
    onFind('.isClickable', function (element) {
        on('click', function (evt) {
            if (!is('a', evt.target) && !is('button', evt.target)) {
                isClickable(element);
            }
        }, element);
    });
}

function isClickable( element, newTab ){
    let link = findAllIn('a',element)[0];
    let href = link.getAttribute('href');

    if (href === undefined) {
        return;
    }

    let target = link.getAttribute('target');
    if( target === '_blank' || newTab ){
        window.open( href );
    }else{
        window.location.href = href;
    }
}