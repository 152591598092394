"use strict";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {addClass, findAllIn, hasClass, removeClass, on, closest} from './elements-packages/dom-utils';

export function init () {
    onFind('.js-nav', function (nav) {
        let subnavToggles = findAllIn('.js-nav__item-link', nav);

        subnavToggles.map((item) => {
            on('click', function(evt){
                evt.preventDefault();
                evt.stopImmediatePropagation();

                if (hasClass('is-open', closest('.js-nav__item', item))) {
                    closeDropdown(item);
                } else {
                    addClass('is-open', item);
                    addClass('is-open', closest('.js-nav__item', item));

                    window.addEventListener("click", function _listener(evt) {
                        if (!closest('.js-nav__item', item).contains(evt.target)) {
                            closeDropdown(item);

                            window.removeEventListener("click", _listener, true);
                        }
                    }, true);
                }
            }, item);
        });
    });
}

function closeDropdown(item) {
    removeClass('is-open', item);
    removeClass('is-open', closest('.js-nav__item', item));
}