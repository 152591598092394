import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
//
import * as tabToggle from './scripts/tab-toggle';
tabToggle.init();

import * as multiTab from './scripts/multi-tab';
multiTab.init();
