import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, addClass, removeClass} from '@elements/dom-utils';

export function init () {
    if(matchMedia('(max-width: 767px)').matches) {
        onFind('.js-guest-card', function (baseElement) {
            console.log('guest card, ', baseElement);
            let viewportHeight = window.innerHeight;
            let image = findIn('.js-guest-card__image', baseElement);
            console.log('IMAGE', image);

            window.addEventListener('scroll', () => {
                if(window.scrollY > viewportHeight) {
                    addClass('is-hidden', image);
                }else{
                    removeClass('is-hidden', image);
                }
            }, {passive: true});
        });
    }
}

