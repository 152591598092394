"use strict";
import {on, matches} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.trackable', function (element) {
        on('click', function (evt) {
            let item = evt.target;
            let href,
                target;

            if (matches('a', item)){
                e.preventDefault();
                href = item.getAttribute('href');
                target = item.getAttribute('target');
            }

            try{
                dataLayer.push({'event':'click event', 'category': item.getAttribute('data-category'), 'action' : item.getAttribute('data-action'), 'label' : item.getAttribute('data-label')});
            }catch(error){

            }finally{
                if (matches('a', item) && item.getAttribute('data-toggle') !== 'tab' && href){
                    window.open(href,(!target ? '_self' : target));
                }
            }

        }, element);
    });
}