/** @jsx h */

import {h, render} from "preact";
import {onFind} from "@elements/init-modules-in-scope";
import throwError from "@elements/throw-error";

import Select from 'react-select/creatable';
import {value} from "lodash/seq";

export function init() {
    onFind('.js-typeahead', function (element) {
        let _typeaheadData = window['_typeaheadData'];

        if (!_typeaheadData)
            throwError('global var _typeaheadData is not set');

        let typeaheadDataName = element.dataset.typeaheadDataName;
        if (!typeaheadDataName)
            throwError('missing data-typeahead-data-name attribute for typeahead element', element);

        if (!_typeaheadData[typeaheadDataName])
            throwError(`missing entry ${typeaheadDataName} in global var _typeaheadData. Only got`, Object.keys(_typeaheadData).join(', '))

        const options = _typeaheadData[typeaheadDataName].map(x => ({
            ...x,
            label: x.label || x.value
        }));

        let attributeWhitelist = ['placeholder', 'name', 'id'];

        let attributeMap = attributeWhitelist.reduce((accu, attributeName) => {
            return {
                ...accu,
                [attributeName]: element.getAttribute(attributeName)
            }
        }, {});

        render(<Typeahead
            options={options}
            {...attributeMap}
        />, element);
    });
}

function Typeahead({options = [], ...props}) {

    const changeHandler = (props) => {
        if(props){
            if (props.link !== null) {
                location.href = props.link;
            }
        }
    };

    return (
        <Select {...props}
                onChange={changeHandler}
                classNamePrefix="react-select"
                formatCreateLabel={x => x}
                isClearable={true}
                clearable={true}
                options={options}/>
    )
}
