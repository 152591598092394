"use strict";

import {findAll, on, addClass, removeClass, findIn, setAttribute, removeAttribute} from "@elements/dom-utils";

export function init () {
    let videos = findAll('.js-video');

    videos.forEach((videoContainer) => {
        let video = findIn('.js-video__video', videoContainer),
            playButton = findIn('.js-video__play', videoContainer),
            // videoLoading = findIn('.js-video__loading', videoContainer),
            posterElement = findIn('.js-video__poster', videoContainer);

        let title = window.location.href + '-' + document.title;

        if (videoContainer.getAttribute('data-video-url')) {
            const loadVideo = () => {
                video.src = videoContainer.getAttribute('data-video-url');

                video.onload = function (){
                    addClass('is-playing', videoContainer);
                }
            };

            on('click', () => loadVideo(), playButton);

            if (posterElement) {
                on('click', () => loadVideo(), posterElement);
            }
        } else {
            const playVideo = () => {
                video.play();
            };

            on('playing', function () {
                addClass('is-playing', videoContainer);
                removeClass('is-ended', videoContainer);
                addClass('is-finished', videoContainer);

                dataLayer.push({
                    'event': 'gaTriggerEvent',
                    'gaEventCategory': 'video',
                    'gaEventAction': 'play',
                    'gaEventLabel': title
                });

            }, video);

            on('pause', function () {
                removeClass('is-playing', videoContainer);

                dataLayer.push({
                    'event': 'gaTriggerEvent',
                    'gaEventCategory': 'video',
                    'gaEventAction': 'pause',
                    'gaEventLabel': title
                });
            }, video);

            on('seeked', function () {
                console.log('seeked');
                dataLayer.push({
                    'event': 'gaTriggerEvent',
                    'gaEventCategory': 'video',
                    'gaEventAction': 'timeline',
                    'gaEventLabel': title
                });
            }, video);

            on('ended', function () {
                removeClass('is-playing', videoContainer);
                addClass('is-ended', videoContainer);
                addClass('is-finished', videoContainer);

                dataLayer.push({
                    'event': 'gaTriggerEvent',
                    'gaEventCategory': 'video',
                    'gaEventAction': 'ended',
                    'gaEventLabel': title
                });
            }, video);

            if (playButton) {
                on('click', () => playVideo(), playButton);
            }
            if (posterElement) {
                on('click', () => playVideo(), posterElement);
            }
        }
    });
}

        //todo lightbox video
        // var videoLightbox = video.closest('.col').find('.js-lightbox');
        // videoLightbox.on('mfpClose', function () {
        //     videojs(video.attr('id')).pause();
        //
        //     dataLayer.push({
        //         'event': 'gaTriggerEvent',
        //         'gaEventCategory': 'video',
        //         'gaEventAction': 'close',
        //         'gaEventLabel': title
        //     });
        // });
        //
        // start video when lightbox is open
        // videoLightbox.on('tap', function() {
        //     var link = $(this).attr('href');
        //     var video = $(link).find('.video-js').attr('id');
        //     $('.js-lightbox.video').on('mfpOpen', function(){
        //         if(video.length > 0){
        //             videojs('#' + video).play();
        //         }
        //         video = '';
        //     });
        //     $(window).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', function (evt) {
        //         dataLayer.push({
        //             'event': 'gaTriggerEvent',
        //             'gaEventCategory': 'video',
        //             'gaEventAction': 'fullscreenchange',
        //             'gaEventLabel': title
        //         });
        //     });
        // });