"use strict";
import {findAllIn, on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-datalayer-checkbox', function (element) {
        on('submit', function () {
            let selectedCheckboxes = [];
            let checkedInput = findAllIn('.js-datalayer-checkbox__input:checked', element);

            checkedInput.forEach((item) => {
                selectedCheckboxes.push(item.getAttribute('data-layer-name'));
            });

            let guideSelection = selectedCheckboxes.toString();

            dataLayer.push({
                'event': 'form-trentinolife',
                'guideSelection': guideSelection
            });
        }, element);
    });
}
