"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {trigger, find, findIn, on, findAllIn} from '@elements/dom-utils';

export function init () {
    onFind('.js-lightbox-gallery', function (element) {
        let buttons = findAllIn('.js-open-lightbox', element);

        if(findIn('.js-open-lightbox', element)){
            buttons.map(button => {
                on('click', function (evt) {
                    let firstLightboxItem = find('.js-lightbox__item',element);
                    trigger('click', firstLightboxItem);
                }, button);
            });
        }
    });
}

