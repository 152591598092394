export const lang = {
    en: {
        required: "This field is required",
        email: "This field requires a valid e-mail address",
        number: "This field requires a number",
        integer: "This field requires an integer value",
        url: "This field requires a valid website URL",
        tel: "This field requires a valid telephone number",
        maxlength: "This fields length must be < ${1}",
        minlength: "This fields length must be > ${1}",
        min: "Minimum value for this field is ${1}",
        max: "Maximum value for this field is ${1}",
        pattern: "Please match the requested format",
        equals: "The two fields do not match",
        default: "Please enter a correct value"
    },
    de: {
        required: "Dies ist ein Pflichtfeld",
        email: "Die Eingabe muss eine gültige E-Mail-Adresse sein",
        number: "Die Eingabe muss eine Zahl sein",
        integer: "Die Eingabe muss eine Zahl sein",
        url: "Die Eingabe muss eine gültige URL sein",
        tel: "This field requires a valid telephone number",
        maxlength: "Die Eingabe ist zu lang. Es dürfen höchstens ${1} Zeichen eingegeben werden",
        minlength: "Die Eingabe ist zu kurz. Es müssen mindestens ${1} Zeichen eingegeben werden",
        min: "Die Eingabe muss größer oder gleich ${1} sein",
        max: "Die Eingabe muss kleiner oder gleich ${1} sein",
        pattern: "Die Eingabe scheint ungültig zu sein",
        equals: "Dieses Feld muss dem anderen entsprechen",
        default: "Die Eingabe scheint nicht korrekt zu sein"
    },
    it: {
        required: "Questo campo è obbligatorio",
        email: "Questo campo richiede un indirizzo e-mail valido",
        number: "Questo campo richiede un numero",
        integer: "Questo campo richiede un valore intero",
        url: "Questo campo richiede un URL del sito web valido",
        tel: "Questo campo richiede un numero di telefono valido",
        maxlength: "La lunghezza di questo campo deve essere < ${1}",
        minlength: "La lunghezza di questo campo deve essere > ${1}",
        min: "Il valore minimo per questo campo è ${1}",
        max: "Il valore massimo per questo campo is ${1}",
        pattern: "Per favore, inserisci il formato richiesto",
        equals: "I due campi non corrispondono",
        default: "Inserisci un valore corretto"
    }
};