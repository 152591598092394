import initInScope, {onFind} from "@elements/init-modules-in-scope";
import {addClass, after, append, before, find, off, on, triggerWith} from "@elements/dom-utils";
import 'whatwg-fetch'; // IE10 Polyfill


export function init() {
    onFind('.js-ajaxInclude', function (element) {
        on('ajaxInclude', ajaxIncludeCallback, element);
        createExtendAjaxInclude(element, {
            device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
        });

        if (element.matches('[data-interaction=click], [data-interaction="load-more-click"]')) {
            on('click', function () {
                addClass('is-loading', element);
                removeDataAttribute('interaction');

                on('ajaxInclude', ajaxIncludeCallback, element);
                createExtendAjaxInclude(element, {
                    device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
                });
            }, element)
        }

        // if (element.matches('[data-interaction="load-more-click"]')){
        //     let ajaxUrl = getAjaxIncludeUrl(element);
        //     let loadedUrls = [];
        //
        //     try {
        //         loadedUrls = JSON.parse(sessionStorage.getItem('ajax-include-load-more-urls')) || [];
        //     } catch(e) {}
        //
        //     if (loadedUrls.indexOf(ajaxUrl) >= 0) {
        //         removeDataAttribute('data-interaction');
        //
        //         on('ajaxInclude', ajaxIncludeCallback, element);
        //         extendAjaxInclude(element, {
        //             device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
        //         });
        //     }
        //
        //     on('click', function (evt) {
        //         evt.preventDefault();
        //         let ajaxUrl = getAjaxIncludeUrl(element);
        //
        //         addClass('is-loading', element);
        //         removeDataAttribute('interaction');
        //
        //         on('ajaxInclude', ajaxIncludeCallback, element);
        //         extendAjaxInclude(element, {
        //             device: matchMedia('(max-width: 767px)').matches ? 'mobile' : 'desktop'
        //         });
        //
        //         try {
        //             var alreadyLoadedUrls = JSON.parse(sessionStorage.getItem('ajax-include-load-more-urls')) || [];
        //             if (alreadyLoadedUrls.indexOf(ajaxUrl) < 0) {
        //                 alreadyLoadedUrls.push(ajaxUrl);
        //                 //disabling this, as the sessionstorage was holding multiple items of the same page which resulted in double, triple, .. loading of events
        //                 //sessionStorage.setItem('ajax-include-load-more-urls', JSON.stringify(alreadyLoadedUrls));
        //             }
        //         } catch(e) {}
        //     }, element);
        // }

        function ajaxIncludeCallback() {
            console.log('ajax include callback!!!!!!!!!!!!!!!!!!!!')
            let includedContainer = this.nextElementSibling;

            if (this.matches('[data-target]')) {
                let targetContainer = find(this.dataset.target);
                if (targetContainer) {
                    includedContainer = targetContainer;
                }
            }
            initInScope(includedContainer);
            off('ajaxInclude', ajaxIncludeCallback, this);

            if (_config && _config['debug'] || true) {
                console.warn('ajax included: ', includedContainer);
            }
        }
    });
}

const defaultOptions = {
    defaultOffset: 500,
    ttl: 60,
    noStorage: false,
    device: ''
};

function createExtendAjaxInclude(element, options) {
    if (element.matches('[data-interaction]')) {
        if (element.dataset.extendAjaxInclude) {
            return element.dataset.extendAjaxInclude;
        }
    }


    options = {
        ...defaultOptions,
        ...options
    };

    let scrollHandle = [];
    let innerHeight = window.innerHeight;

    extendAjaxInclude(element);
    on('resize', function () {
        innerHeight = window.innerHeight;
    }, window);

    // Listen to Scroll event
    on('scroll', function () {
        if (scrollHandle.length) {
            innerHeight = window.innerHeight
            scrollHandler();
        }
    }, window);

    function scrollHandler() {
        scrollHandle.forEach(function (currentElement, index) {
            let winTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

            if ((currentElement.offset().top - currentElement.dataset.scrolloffset) < winTop + innerHeight) {
                scrollHandle.splice(index, 1);
                callAjaxInclude(element);
            }
        });
    }

    function callAjaxInclude(element) {
        on('ajaxInclude', function (event) {
            let deviceKey = '';
            if (options.device) {
                deviceKey = '-' + options.device;
            }
            if (element.matches('[data-storage]')) {
                store(this.dataset.storage + deviceKey, event.detail);
            }
        }, element);

        ajaxInclude(element, options);
    }

    function extendAjaxInclude() {
        // if (typeof _obj != 'undefined') {
        //     el = $(_obj[0]);
        // }
        let hasDataContent = true;
        let methods = ["append", "replace", "before", "after"];
        let method = methods.filter(currentMethod => {
            return element.matches(`[data-${currentMethod}]`)
        })[0];

        if (method) {
            if (options.device !== '') {
                element.dataset[method] = `${element.dataset[method]}${element.dataset[method].match(/\?/) ? '&' : '?'}device=${options.device}`;
            }

            if (typeof element.dataset[method] == 'undefined') {
                hasDataContent = false;
            }
        }

        if (hasDataContent) {
            let deviceKey = '';
            if (options.device !== '') {
                deviceKey = '-' + options.device;
            }

            let renewStorage = false,
                ttl = null;

            console.log('element.matches(\'[data-ttl]\')', element.matches('[data-ttl]'));
            if (element.matches('[data-ttl]')) {
                ttl = (element.dataset('ttl') * 1000);
                let myTime = new Date();

                console.log(getFromStore(`${element.dataset.storage + deviceKey}--ttl`), myTime.getTime());
                console.log('getFromStore(`${element.dataset.storage + deviceKey}--ttl`) < myTime.getTime()', getFromStore(`${element.dataset.storage + deviceKey}--ttl`) < myTime.getTime());
                if (getFromStore(`${element.dataset.storage + deviceKey}--ttl`)) {
                    if (getFromStore(`${element.dataset.storage + deviceKey}--ttl`) < myTime.getTime()) {
                        renewStorage = true;
                    }
                } else {
                    renewStorage = true;
                }

                if (renewStorage) {
                    myTime = new Date();
                    myTime = myTime.getTime() + ttl;
                    store(element.dataset.storage + deviceKey + '--ttl', myTime);
                }
            }

            if (!getFromStore(element.dataset.storage + deviceKey) || options.noStorage || renewStorage) {
                if (element.matches('[data-scrolloffset]')) {
                    element.dataset.scrolloffset = element.dataset.scrolloffset || options.defaultOffset;
                    scrollHandle.push(element);
                } else {
                    callAjaxInclude(element);
                }
            } else {
                let media = element.dataset.media;
                if (!media || (window.matchMedia && window.matchMedia(media).matches) || (element.dataset.fallback !== undefined && !window.matchMedia('(min-width: 0px)').matches)) {

                    let content = getFromStore(element.dataset.storage + deviceKey);
                    let targetElement = element;

                    if (element.dataset.target) {
                        targetElement = find(element.dataset.target);
                    }

                    console.log(method, content);
                    switch (method) {
                        case "replace":
                            after(content, {allowScriptTags: true}, targetElement);
                            triggerWith('ajaxInclude', content, element);
                            element.remove();
                            break;
                        case "append":
                            append(content, {allowScriptTags: true}, targetElement);
                            break;
                        case "before":
                            before(content, {allowScriptTags: true}, targetElement);
                            break;
                        case "after":
                            after(content, {allowScriptTags: true}, targetElement);
                            break;
                    }

                    if (method !== "replace") {
                        triggerWith('ajaxInclude', content, element)
                    }
                }

            }
        }

        scrollHandler();
    }

    // todo return api
    return {}
}

const AI = {
    boundAttr: "data-ajax-bound",
    interactionAttr: "data-interaction",
    // request a url and trigger ajaxInclude on elements upon response
    makeReq: function (url, elements, isHijax) {
        console.log('makeReq', url, elements);
        fetch(url)
            .then(response => response.text()).then(function (data) {
            if (!Array.isArray(elements)) {
                elements = [elements];
            }
            elements.map(triggerWith("ajaxIncludeResponse", data));
        })
    }
};

function ajaxInclude(element, options) {
    let urllist = [],
        elementQueue = [],
        o = {
            proxy: null
        };

    o = {...o, ...options};

    // if it's a proxy, que the element and its url, if not, request immediately
    function queueOrRequest(el) {
        let url = el.dataset.url;
        if (o.proxy && urllist.includes(url)) {
            urllist.push(url);
            elementQueue.push(el);
        } else {
            AI.makeReq(url, el);
        }
    }

    // if there's a url queue
    function runQueue() {
        if (urllist.length) {
            AI.makeReq(o.proxy + urllist.join(","), elementQueue);
            elementQueue = [];
            urllist = [];
        }
    }

    // bind a listener to a currently-inapplicable media query for potential later changes
    function bindForLater(el, media) {
        var mm = win.matchMedia(media);

        function cb() {
            queueOrRequest(el);
            runQueue();
            mm.removeListener(cb);
        }

        if (mm.addListener) {
            mm.addListener(cb);
        }
    }

    if (!element.matches("[" + AI.boundAttr + "]") && !element.matches("[" + AI.interactionAttr + "]")) {
        let media = element.dataset.media,
            methods = ["append", "replace", "before", "after"],
            method,
            url,
            isHijax = false,
            target = element.dataset.target;

        for (let ml = methods.length, i = 0; i < ml; i++) {
            if (element.matches("[data-" + methods[i] + "]")) {
                method = methods[i];
                url = element.dataset[method];
            }
        }

        if (!url) {
            // <a href> or <form action>
            url = element.getAttribute("href") || element.getAttribute("action");
            isHijax = true;
        }

        element.dataset.method = method;
        element.dataset.url = url;
        element.dataset.target = target;
        element.setAttribute(AI.boundAttr, true);
        on("ajaxIncludeResponse", function (event) {
            let content = event.detail,
                targetElement = target || element;

            if (o.proxy) {
                var subset = content.match(new RegExp("<entry url=[\"']?" + element.dataset.url + "[\"']?>(?:(?!</entry>)(.|\n))*", "gmi"));
                if (subset) {
                    content = subset[0];
                }
            }

            // let filteredContent = triggerWith("ajaxIncludeFilter", content, element);
            //
            // if (filteredContent) {
            //     content = filteredContent;
            // }
            
            switch (method) {
                case "replace":
                    after(content, {allowScriptTags: true}, targetElement);
                    triggerWith('ajaxInclude', content, element);
                    element.remove();
                    break;
                case "append":
                    append(content, {allowScriptTags: true}, targetElement);
                    break;
                case "before":
                    before(content, {allowScriptTags: true}, targetElement);
                    break;
                case "after":
                    after(content, {allowScriptTags: true}, targetElement);
                    break;
            }

            if (method !== "replace") {
                triggerWith('ajaxInclude', content, element)
            }
        }, element);

        // When hijax, ignores matchMedia, proxies/queueing
        if (isHijax) {
            AI.makeReq(url, element, true);
        } else if (!media
            || (window.matchMedia && window.matchMedia(media).matches)
            || (element.dataset.fallback !== undefined && !window.matchMedia('(min-width: 0px)').matches)) {
            queueOrRequest(element);
        } else if (media && window.matchMedia) {
            bindForLater(element, media);
        }
    }


    // empty the queue for proxied requests
    runQueue();

    // return elems
    return this;
}


// helper functions
function removeDataAttribute(attribut, element) {
    delete element.dataset[attribut];
}

function getAjaxIncludeUrl(element) {
    return element.dataset.replace || element.dataset.before || element.dataset.after || element.dataset.append
}


// store
function store(key, data) {
    if (!supportsSessionStorage()) {
        return;
    }

    try {
        return sessionStorage.setItem(key, data);
    } catch (e) {
        clearStore();
    }
}

function getFromStore(key) {
    try {
        if (!supportsSessionStorage()) {
            return;
        }

        return sessionStorage.getItem(key);
    } catch (e) {
    }
}

function supportsSessionStorage() {
    let doesSupport = true;
    try {
        sessionStorage.setItem('test', '1');
        sessionStorage.removeItem('test');
        doesSupport = !!localStorage && !!window['localStorage'];
    } catch (_error) {
        doesSupport = false;
    }
    return doesSupport;
}

function clearStore() {
    if (supportsSessionStorage()) {
        sessionStorage.clear();
    }
}