"use strict";
import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-action-changer', function (element) {
        on('submit', function () {
            let newAction = element.getAttribute('data-action');

            if (newAction) {
                element.setAttribute('action', newAction);
            } else {
                element.setAttribute('action', '');
            }
        }, element);
    });
}