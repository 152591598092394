"use strict";
import {on, findIn, findAllIn, closest, hasClass, addClass, removeClass, findAll} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-quickfinder', function (quickfinder) {
        let durationSelect = findIn('.js-quickfinder__duration', quickfinder),
            addButton = findIn('.js-quickfinder__add', quickfinder);

        on('change', function () {
            findIn('.js-quickfinder__duration-type', quickfinder).value = durationSelect.options[durationSelect.selectedIndex].getAttribute('data-type');
        }, durationSelect);

        on('click', function (evt) {
            evt.preventDefault();
            let count = findAllIn('.js-quickfinder__row', quickfinder).length;

            let newRow = findIn('.js-quickfinder__row:first-child', quickfinder).cloneNode(true);

            let ca = findAllIn('[name="ca0[]"]', newRow);
            let c = findAllIn('[name="c0"]', newRow);
            let a = findAllIn('[name="a0"]', newRow);
            let u = findAllIn('[name="u0"]', newRow);

            ca.forEach((item) => {
                console.log('item',item);
                item.setAttribute('name', 'ca'+ count +'[]');
            });

            c.forEach((item) => {
                console.log('item',item);
                item.setAttribute('name', 'c'+ count +'');
            });

            a.forEach((item) => {
                console.log('item',item);
                item.setAttribute('name', 'a'+ count +'');
            });

            u.forEach((item) => {
                console.log('item',item);
                item.setAttribute('name', 'u'+ count +'');
            });
            findIn('.js-quickfinder__row-count', newRow).innerHTML = count + 1;
            removeClass('hidden', findIn('.js-quickfinder__remove', newRow));

            findIn('.js-quickfinder__row:last-child', quickfinder).parentNode.insertBefore(newRow, findIn('.js-quickfinder__row:last-child', quickfinder).nextSibling);

            initNewRow(newRow);
        }, addButton);

        countSelectedChilds(quickfinder);
    });
}


function countSelectedChilds(quickfinder) {
    findAllIn('.js-quickfinder__child', quickfinder).map((childInput) => {
        on('change', function () {
            if (childInput.value.length > 0) {
                addClass('is-selected', childInput);
            } else {
                removeClass('is-selected', childInput);
            }

            let row = closest('.js-quickfinder__row', childInput);

            findIn('.js-quickfinder__child-count', row).value = findAllIn('.js-quickfinder__child.is-selected', row).length;
        }, childInput);
    })
}

function initNewRow(row) {
    let removeButton = findIn('.js-quickfinder__remove', row);

    on('click', function (evt) {
        evt.preventDefault();
        findIn('.js-quickfinder__row:last-child', closest('.js-quickfinder', row)).remove();
    }, removeButton);

    countSelectedChilds(row);
}
