"use strict";

import {on, findAll, findAllIn, closest, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-map-info', function (item) {
        on('click', function(evt){
            evt.preventDefault();

            //reset active states
            findAll('.js-map-info').map((infoItem) => {
                infoItem.setAttribute('class', 'js-map-info');
            });

            let dataId = item.getAttribute('data-map-group'),
                classes = item.getAttribute('class');

            item.setAttribute('class', ''+ classes +' is-active');

            findAll('.interactive-map-regions .js-map-info').map((infoItem) => {
                if (infoItem.getAttribute('data-map-group') === dataId) {
                    infoItem.setAttribute('class', ''+ classes +' is-active');
                }
            });

            if(closest('.interactive-map-regions--hover', item).getAttribute('data-map-dropdown')){
                findAll('.js-interactive-dropdown option').map((option) => {
                    if (option.getAttribute('data-map-id') === dataId) {
                        option.setAttribute('selected', 'selected');
                    }
                });
            }else {
                //todo gibts das überhautp noch ?
                console.log('else .interactive-map-infobox')
                // $('.interactive-map-infobox').each(function(){
                //     $(this).removeClass('is-active');
                //     if($(this).is(dataId)){
                //         $(this).addClass('is-active');
                //     }
                // });
            }
        }, item);
    });

    //Close button infobox
    onFind('.js-close-infobox', function (closeButton) {
        on('click', function(){
            removeClass('is-active', closest('.interactive-map-infobox', closeButton));
            // $(this).closest('.interactive-map-infobox').removeClass('is-active');

            findAll('.js-map-info').map((infoItem) => {
                infoItem.setAttribute('class', 'js-map-info');
            });
            // $('.js-map-info').each(function(){
            //     $(this).attr('class', 'js-map-info');
            // });
        }, closeButton);
    });
}