import {curry} from "ramda";
import throwError from "@elements/throw-error";

// Dom traversing
console.log('test dom utils');
export const find = selector => findIn(selector, document);
export const findAll = selector => findAllIn(selector, document);
export const findIn = curry((selector, element) => element.querySelector(selector));
export const findAllIn = curry((selector, element) => toArray(element.querySelectorAll(selector)));

export const closest = curry((selector, element) => element.closest(selector));

//Dom manipulation
export const setText = curry((text, element) => element.textContent = text);
export const empty = element => element.textContent = '';

// Class manipulation
// todo ie11
export const addClass = curry((className, element) => {
    element.classList.add(className);
    return element;
});
export const removeClass = curry((className, element) => {
    element.classList.remove(className);
    return element;
});
export const toggleClass = curry((className, element) => {
    element.classList.toggle(className);
    return element;
});
export const hasClass = curry((className, element) => element.classList.contains(className));
export const matches = curry((selector, element) => (element.matches || element.matchesSelector || element.msMatchesSelector || element.mozMatchesSelector || element.webkitMatchesSelector || element.oMatchesSelector).call(element, selector));
export const is = matches;

// Attributes
export const setAttribute = curry((name, value, element) => element.setAttribute(name, value));
export const removeAttribute = curry((name, element) => element.removeAttribute(name));

// Events
export const on = curry((eventType, fnc, element) => {
    element.addEventListener(eventType, fnc);
    return element;
});

export const off = curry((eventType, fnc, element) => {
    element.removeEventListener(eventType, fnc);
    return element;
});

export const trigger = curry((eventType, element) => {
    element.dispatchEvent(new Event(eventType, {bubbles: true}));
    return element;
});

export const triggerWith = curry((eventType, eventData, element) => {
    element.dispatchEvent(new CustomEvent(eventType, {'detail': eventData}));
    return element;
});

// Element creation
export const createElementsByHTML = (markup, {
    allowScriptTags = false
} = {}) => {
    if (allowScriptTags) {
        executeScriptTags(markup);
    } else {
        let scripts = [...findScriptsInHTML(markup), ...findExternalScriptsSourcesInHTML(markup)];
        if (scripts && scripts.length) {
            // Todo add alternativ solution to error message
            throwError('Detected script tags: Please do not use script tags in your dynamically added HTML', scripts.join('\n'));
        }
    }

    let tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = markup;

    return toHTMLElementArray(tmp.body.children);
};

export const append = curry((markup, options, container) => {
    if (options && options.allowScriptTags === true) {
        executeScriptTags(markup);
        loadExternalScripts(markup);
    } else {
        let scripts = [...findScriptsInHTML(markup), ...findExternalScriptsSourcesInHTML(markup)];
        if (scripts && scripts.length) {
            // Todo add alternativ solution to error message
            throwError('Detected script tags: Please do not use script tags in your dynamically added HTML', scripts.join('\n'));
        }
    }

    container.insertAdjacentHTML('beforeend', markup);
    return container;
});

export const after = curry((markup, options, container) => {
    if (options && options.allowScriptTags === true) {
        executeScriptTags(markup);
        loadExternalScripts(markup);
    } else {
        let scripts = [...findScriptsInHTML(markup), ...findExternalScriptsSourcesInHTML(markup)];
        if (scripts && scripts.length) {
            // Todo add alternativ solution to error message
            throwError('Detected script tags: Please do not use script tags in your dynamically added HTML', scripts.join('\n'));
        }
    }

    container.insertAdjacentHTML('afterend', markup);
    return container;
});

export const before = curry((markup, options, container) => {
    if (options && options.allowScriptTags === true) {
        executeScriptTags(markup);
        loadExternalScripts(markup);
    } else {
        let scripts = [...findScriptsInHTML(markup), ...findExternalScriptsSourcesInHTML(markup)];
        if (scripts && scripts.length) {
            // Todo add alternativ solution to error message
            throwError('Detected script tags: Please do not use script tags in your dynamically added HTML', scripts.join('\n'));
        }
    }

    container.insertAdjacentHTML('beforebegin', markup);
    return container;
});

export const replace = curry((markup, options, node) => {
    if (options && options.allowScriptTags === true) {
        executeScriptTags(markup);
        loadExternalScripts(markup);
    } else {
        let scripts = [...findScriptsInHTML(markup), ...findExternalScriptsSourcesInHTML(markup)];
        if (scripts && scripts.length) {
            // Todo add alternativ solution to error message
            throwError('Detected script tags: Please do not use script tags in your dynamically added HTML', scripts.join('\n'));
        }
    }

    node.replaceWith(createElementsByHTML(markup));
});


// Misc
export const toHTMLElementArray = x => {
    if (NodeList.prototype.isPrototypeOf(x) || HTMLCollection.prototype.isPrototypeOf(x)) {
        return Array.from(x);
    } else if (Array.isArray(x)) {
        return x;
    } else {
        return [x];
    }
};

export const findScriptsInHTML = markup =>
    Array.from(markup.matchAll(/<script[^>]*>(.*?)<\/script>/gsi))
        .map(([_, scriptContent]) => scriptContent).filter(content => !!content);

export const findExternalScriptsSourcesInHTML = markup =>
    Array.from(markup.matchAll(/<script[^>]* src="([^"]*)"[^>]*>[^<]*<\/script>/gsi))
        .map(([_, scriptContent]) => scriptContent);

export const executeScriptTags = markup => {
    let scripts = findScriptsInHTML(markup);

    if (scripts && scripts.length) {
        scripts.forEach(eval)
    }
};

export const loadExternalScripts = markup => {
    let scriptSources = findExternalScriptsSourcesInHTML(markup);

    if (scriptSources && scriptSources.length) {
        scriptSources.forEach(() => {
            fetch(src)
                .then(response => response.text())
                .then(eval)
        })
    }
};


// private helper
const toArray = iterable => Array.from(iterable);

