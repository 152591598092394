"use strict";

import {on,addClass,closest} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import lightGallery from "lightgallery";
import loadScript from '@elements/load-script';

export function init () {
    onFind('.js-lightbox-dynamic', function (lightboxDynItem) {
        let gallery;
        const dynamicEl = JSON.parse(closest('.js-lightbox', lightboxDynItem).getAttribute('data-lightbox-dynamic-el'));
        const index = parseInt(lightboxDynItem.getAttribute('data-dynamic-lightbox-index')) || 0;

        import('lightgallery/plugins/video/lg-video.umd.js').then((module) => {
            let options = {
                plugins: [module.default],
                video: true,
                dynamic: true,
                dynamicEl: dynamicEl,
                download: false,
                index: index,
                licenseKey: '7AF43751-F35F4C70-A604AE1C-12C521AE'
            }

            loadScript("https://player.vimeo.com/api/player.js");

            gallery = lightGallery(lightboxDynItem, options);
        });

        addClass('lightbox-is-init',lightboxDynItem);

        on('click', (evt) => {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            lightboxDynItem.style.pointerEvents = "none";

            if (gallery) {
                gallery.openGallery(index);
            }
            lightboxDynItem.style.pointerEvents = "auto";
        }, lightboxDynItem);
    });
}