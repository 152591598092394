"use strict";
import {createSwiper} from "./swiper";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, addClass} from '@elements/dom-utils';

export function init () {

    onFind('.js-moodboard-slider', function (sliderElement) {

        createSwiper(sliderElement, {
                slidesPerView: 1.4,
                spaceBetween: 10,
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-moodboard-slider__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-moodboard-slider__prev', swiperContainer),
                },
                breakpoints: {
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 4,
                    }
                },
                on: {
                    init: function () {
                        console.log('swiper initialized', sliderElement);
                        addClass('is-init', sliderElement)
                    },
                },
            },
            {
                sliderContainer: '.js-moodboard-slider',
                slider: '.js-moodboard-slider__slider'
            });

    });
}

