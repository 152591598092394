"use strict";
import {createSwiper} from "./swiper";
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";
import {findIn, on, removeClass} from '@elements/dom-utils';
import * as video from "./video";

export function init () {
    onFind('.js-header-image-slider', function (sliderElement) {
        if(matchMedia('(max-width: 767px)').matches) {
            let swiper = createSwiper(sliderElement, {
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        findNextElement: swiperContainer => findIn('.js-header-image-slider__next', swiperContainer),
                        findPrevElement: swiperContainer => findIn('.js-header-image-slider__prev', swiperContainer),
                    },
                    pagination: {
                        el: swiperContainer => findIn('.js-header-image-slider__pagination', swiperContainer),
                        clickable: true,
                        type: 'fraction',
                    }
                },
                {
                    sliderContainer: '.js-header-image-slider',
                    slider: '.js-header-image-slider__slider'
                });

            swiper.on('slideChange',() => {
                let previousSlide = swiper.slides[swiper.previousIndex];
                let previousSlideVideoContainer = findIn('.js-video', previousSlide);
                let previousSlideVideo = findIn('.js-video__video', previousSlide);

                if(previousSlideVideo) {
                    previousSlideVideo.pause();
                    removeClass('is-playing', previousSlideVideoContainer);
                    video.init();
                }
            });
        }
    });
}

