/** @jsx h */

import {h, render} from "preact";
import {components} from 'react-select';
import {useState} from "preact/hooks";
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import AsyncSelect from 'react-select/async';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc'
import fetch from '@elements/fetch';

const defaultSelectors = {
    base: '.js-react-multiselect'
};

const defaultOptions = {
    selectAll: 'select all',
    removeAll: 'remove all',
    placeholder: 'select...',
    loadingMessage: 'loading...',
    noOptionsMessage: 'no Options available',
    name: 'regions[]',
    townsUrl: '/var/tmp/typeahead/de/towns.json?_dc=',
    regionsUrl: '/var/tmp/typeahead/de/regions.json?_dc=',
    townsLabel: 'Towns',
    regionsLabel: 'Regions'
};

let townsArray = [];
let regionsArray = [];
let optionArray = [];

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, (base) => {
        let options = {
            ...defaultOptions,
            ...getPrefixedDataSet('react-multiselect', base)
        }

        let today = new Date();
        let dc = today.getFullYear().toString() + today.getMonth().toString() + today.getDate().toString() + today.getHours().toString();

        let townsUrl = options.townsUrl + dc;
        let regionsUrl = options.regionsUrl + dc;

        const townsRequest = (townsUrl) => new Promise((resolve, reject) => {
            let pendingRequest = fetch(townsUrl, {
                method: 'get'
            });
            pendingRequest.then((result) => {
                return result.clone().json()
            }).then((data) => {
                let returnOptions = [];

                data.items.forEach(function (item) {
                    returnOptions.push(
                        {
                            'id': item.id.toString(),
                            'label': item.name,
                            'value': item.id.toString(),
                            'name': 'towns[]',
                        }
                    );
                });
                resolve(returnOptions);
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                    reject('Error towns');
                }
            });
        });
        const regionsRequest = (regionsUrl) => new Promise((resolve, reject) => {
            let pendingRequest = fetch(regionsUrl, {
                method: 'get'
            });
            pendingRequest.then((result) => {
                return result.clone().json()
            }).then((data) => {
                let returnOptions = [];

                data.items.forEach(function (item) {
                    returnOptions.push(
                        {
                            'id': item.id.toString(),
                            'label': item.name,
                            'value': item.id.toString(),
                            'name': 'regions[]',
                        }
                    );
                });
                resolve(returnOptions);

            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                    reject('Error regions');
                }
            });
        });

        Promise.all([townsRequest(townsUrl), regionsRequest(regionsUrl)])
            .then((result) => {

                townsArray = result[0];
                townsArray.map((town) => {
                    let label = town.label;
                    return town.label = label.substring(0, label.indexOf('/')-1);
                });

                regionsArray = result[1];

                if(townsArray !== [] || regionsArray !== []){
                    optionArray = [
                        {
                            label: options.townsLabel,
                            options: townsArray
                        },
                        {
                            label: options.regionsLabel,
                            options: regionsArray
                        }
                    ]
                }

                render(<MultiSelectSort
                    selectAllTranslation={options.selectAll}
                    removeAllTranslation={options.removeAll}
                    name={options.name}
                    loadingMessage={options.loadingMessage}
                    noOptionsMessage={options.noOptionsMessage}
                    placeholder={options.placeholder}/>, base);

            }).catch(console.log)
    });
}

async function searchArray(inputValue, options){
    let result1 = options[0].options.filter( obj => {
        let label = obj.label.toLowerCase();
        let input = inputValue.toLowerCase()

        return label.includes(input);
    });
    let result2 = options[1].options.filter( obj => {
        let label = obj.label.toLowerCase();
        let input = inputValue.toLowerCase()

        return label.includes(input);
    });

    return await ([
        {
            "label": options[0].label,
            "options": result1
        },
        {
            "label": options[1].label,
            "options": result2
        }
    ]);
}

function arrayMove(array, from, to) {
    const slicedArray = array.slice();
    slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0]
    );
    return slicedArray;
}

const SortableMultiValue = SortableElement(
    props => {
        return <components.MultiValue {...props} />;
    }
);

const SortableMultiValueLabel = SortableHandle(
    props => {
        const onMouseOver = (e) => {
            e.target.style.cursor = 'grab';
        }
        const onMouseDown = (e) => {
            e.target.style.cursor = 'grabbing';
        }
        const onMouseUp = (e) => {
            e.target.style.cursor = 'grab';
        }
        const innerProps = {...props.innerProps, onMouseOver, onMouseDown, onMouseUp}
        return <components.MultiValueLabel {...props} innerProps={innerProps} />
    }
);

const SortableSelect = SortableContainer(AsyncSelect);

export default function MultiSelectSort(
    {
        options = [],
        selectAllTranslation = "",
        removeAllTranslation = "",
        placeholder = "",
        loadingMessage = "",
        noOptionsMessage = "",
        name = "",
    }) {

    const [selected, setSelected] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const onChange = (selectedOptions) => {
        if(selectedOptions !== null){
            if( selectedOptions.find(option => option.value === "all")){
                if(selected.length === options.length){
                    setSelected([]);
                }else{
                    setSelected(options);
                }
            }else{
                setSelected(selectedOptions);
            }
        }else{
            setSelected([]);
        }

        onFind('.js-react-multiselect__result', function(result){
            if(result.childNodes.length !== 0){
                result.innerHTML = null;
            }

            let htmlContent;
            if(selectedOptions !== null){
                selectedOptions.forEach(function(item){
                    if(htmlContent === undefined || htmlContent === null){
                        htmlContent = '<input type="hidden" name="' + item.name + '" value="' + item.value +'" />';
                    }else{
                        htmlContent += '<input type="hidden" name="' + item.name + '" value="' + item.value +'" />';
                    }
                });
            }else{
                htmlContent = null;
            }
            result.innerHTML = htmlContent;
        });
    }

    const onInputChange = (inputValue) => {
        if(inputValue !== ''){
            setMenuIsOpen(true);
        }else{
            setMenuIsOpen(false);
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        setSelected(newValue);
        console.log(
            'Values sorted:',
            newValue.map((i) => i.value)
        );
    };

    return (
        <SortableSelect
            useDragHandle
            // react-sortable-hoc props:
            axis="xy"
            onSortEnd={onSortEnd}
            distance={4}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            // react-select props:
            isMulti
            cacheOptions
            defaultOptions={optionArray}
            loadOptions={(inputValue) => searchArray(inputValue, optionArray)}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            value={selected}
            onChange={onChange}
            className={"react-multiselect"}
            components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
                DropdownIndicator:() => null,
                IndicatorSeparator:() => null
            }}
            placeholder={placeholder}
            label={'Label'}
            closeMenuOnSelect={false}
            name={name}
            menuIsOpen={menuIsOpen}
            onInputChange={(inputValue) => onInputChange(inputValue)}
            formatGroupLabel={formatGroupLabel}
        />
    );
}


const formatGroupLabel = (data) => (
    <div>
        <span>{data.label}</span>
    </div>
);