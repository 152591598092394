"use strict";
import {on, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import fetch from '@elements/fetch';

export function init () {
    onFind('.js-weather', function (container) {
        let select = findIn('.js-weather-change', container),
            resultImg = findIn('.js-weather__img', container),
            resultContainer = findIn('.js-weather__result', container);

        on('change', function(){
            let weatherCode = select.value;

            if (select.options[select.selectedIndex].getAttribute('data-replace')) {
                window.location = select.options[select.selectedIndex].getAttribute('data-replace');
            } else {
                let url = container.getAttribute('data-weather-url') ? window.location.pathname + container.getAttribute('data-weather-url') : '/console_ajaxweather'
                let pendingRequest = fetch(url, {
                    method: 'get',
                    body: {
                        json: 1,
                        language: _config.lang,
                        code: weatherCode
                    }
                });

                pendingRequest.then((result) => {
                    return result.clone().json()
                }).then((result) => {
                    console.log(result)
                    if (result.success) {
                        resultContainer.innerHTML = result.html;
                        if(weatherCode !== ""){
                            resultImg.setAttribute('src', '/static/img/content/maps/' + weatherCode + '.png');
                        } else {
                            resultImg.setAttribute('src', '/static/img/content/maps/all-regions.png');
                        }
                    }
                }).catch((error) => {
                    if (error.name !== 'AbortError') {
                        /*Do error stuff*/
                        console.error(error);
                    }
                });
            }
        }, select);
    });
}