"use strict";

import Swiper from 'swiper';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn,addClass} from "@elements/dom-utils";

const defaultSelectors = {
    sliderContainer: '.js-swiper',
    slider: '.js-swiper__slider',
    sliderScrollbar: '.js-swiper__scrollbar',
    sliderPrev: '.js-swiper__prev',
    sliderNex: '.js-swiper__next'
};

const defaultOptions = {
    mediaQuery : null
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.sliderContainer, function (baseElement) {
        createSwiper(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createSwiper(baseElement, options = defaultOptions, selectors = defaultSelectors) {

    let slider = findIn(selectors.slider, baseElement);


    let sliderOptions = {
        keyboard: {
            enabled: true,
        },
        ...(transformOptions(options,baseElement))
    };

    if(!options.mediaQuery || matchMedia(options.mediaQuery).matches){
        return initSlider(slider, sliderOptions);
    }

}

const transformOptions = (options, container) => {
    options = ({
        ...options,
        scrollbar: {...options.scrollbar},
        navigation: {...options.navigation},
    });

    if (options.scrollbar && options.scrollbar.findElement) {
        options.scrollbar.el = options.scrollbar.findElement(container);
    }

    if (options.navigation && options.navigation.findNextElement) {
        options.navigation.nextEl = options.navigation.findNextElement(container);
    }

    if (options.navigation && options.navigation.findPrevElement) {
        options.navigation.prevEl = options.navigation.findPrevElement(container);
    }

    if (options.pagination && options.pagination.el) {
        options.pagination.el = options.pagination.el(container);
    }

    return options
};

function initSlider(slider, sliderOptions) {
    addClass('is-init', slider);
    return new Swiper(slider, sliderOptions);
}