"use strict";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {addClass, find, findIn, hasClass, findAllIn, removeClass, on} from '@elements/dom-utils';

export function init () {
    let overlays = findAllIn('.js-search-overlay', document.body);

    overlays.map(overlay => {
        if(!hasClass('js-overlay', overlay)){
            onFind('.js-open-search', function (openSearch) {
                on('click', function (evt) {
                    evt.preventDefault();
                    addClass('is-open', overlay);
                    findIn('.js-request-search', overlay).focus();
                }, openSearch);
            });

            onFind('.js-close-search', function (closeSearch) {
                on('click', function (evt) {
                    evt.preventDefault();
                    removeClass('is-open', overlay);
                }, closeSearch);
            });
        }
    })

}
