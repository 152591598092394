"use strict";
import Swiper from 'swiper';
import {onFind} from "@elements/init-modules-in-scope";
import * as datepicker from '@elements/datepicker';
import {findAllIn, find, findIn, hasClass, on, addClass, removeClass} from '@elements/dom-utils';
import {translate} from '@elements/translations';

let socialWebcamShareData = {
    method: 'share',
    href: location.href
};


export function init () {
    onFind('.js-webcam-gallery-slider', function (element) {

        let currentDate = new Date();
        let currentHour = currentDate.getHours();
        let slideToStart = 1;

        if (currentHour >= 7 && currentHour <= 19) {
            let webcamNavItems = findAllIn('.webcam-nav__item', findIn('.js-webcam-gallery-nav-slider__slider',element));

            if (hasClass('disabled', webcamNavItems[currentHour-7])){
                slideToStart = currentHour-8;
            }else{
                slideToStart = currentHour-7;
            }
        } else {
            slideToStart = 0;
        }

        window.location.hash = '#pos=' + todayDate + '~' + slideToStart;


        let sliderNav =  new Swiper(findIn('.js-webcam-gallery-nav-slider__slider',element), {
            loop: false,
            slidesPerView: 4,
            spaceBetween: 1,
            initialSlide: slideToStart,
            noSwiping: true,
            centeredSlides:true,
            centeredSlidesBounds:true,
            a11y: {
                prevSlideMessage: translate('swiperPrev'),
                nextSlideMessage: translate('swiperNext'),
            },
            navigation: {
                nextEl: '.js-webcam-gallery-nav-slider__next',
                prevEl: '.js-webcam-gallery-nav-slider__prev',
            },
            breakpoints: {
                // when window width is >= 1200px
                1200: {
                    slidesPerView: 8,
                },
                768: {
                    slidesPerView: 5,
                }
            }
        });

        sliderNav.updateSize();

        let sliderMain = new Swiper(findIn('.js-webcam-gallery-main-slider__slider', element), {
            loop: false,
            allowTouchMove:false,
            slidesPerView: 1,
            spaceBetween: 10,
            draggable:false,
            noSwiping: true,
            initialSlide: slideToStart,
            thumbs: {
                swiper: sliderNav
            },
            on: {
                slideChange: function () {
                    if (sliderMain){
                        window.location.hash = '#pos=' + todayDate + '~' + sliderMain.activeIndex;
                        socialWebcamShareData.picture = webcamsForShare[sliderMain.activeIndex];
                        find('#saveImageOnHdd').setAttribute('href', '/webcamimagedownload?image=' + webcamsForDownload[sliderMain.activeIndex]);
                    }
                },
            },
        });

        let webcamPlayBtn = findIn('.js-webcam-play', element);

        on('click', function (evt) {
            let playBtn = evt.target;

            if (hasClass('is-active', playBtn)){
                sliderNav.autoplay.stop();
                sliderMain.autoplay.stop();
                removeClass('is-active', playBtn);
            }else{
                sliderNav.autoplay.start();
                sliderMain.autoplay.start();
                addClass('is-active', playBtn);
            }
        }, webcamPlayBtn);

    });

    onFind('.js-datepicker-webcam', (datepickerElement) => {
        Promise.all([datepicker.getApi(datepickerElement)]).then(function([datepickerApi]) {

            let input = findIn('.js-datepicker-webcam__input', datepickerElement);
            on('change', (e) => {
                let date = new Date(datepickerApi.getDate());
                let myDateString;

                myDateString = ('0' + date.getDate()).slice(-2) + '/'
                    + ('0' + (date.getMonth()+1)).slice(-2) + '/'
                    + date.getFullYear();

                location.href = _config.document_path + '?webcamtime=' + myDateString;
            }, input);
        });
    });
}

