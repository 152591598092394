"use strict";
import fetch from "@elements/fetch";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {addClass, find, findIn, findAllIn, removeClass, on} from '@elements/dom-utils';

let currentPage = 1;
export function init () {

    onFind('.js-search', function (container) {
        let searchInput = findIn('.js-search__input', container),
            loading = findIn('.js-search__loading', container),
            loadMore = findIn('.js-search__load-more', container),
            submitButton = findIn('.js-search__submit', container),
            result = findIn('.js-search__result', container);

        let url = new URL((window.location.href).toLowerCase());

        if(url.searchParams.get('keyword')){
            let toCheck = findAllIn('a, p', result);
            toCheck.forEach((item) => {
                item.innerHTML = highlight(item.textContent, url.searchParams.get('keyword'));
            });
        }

        findAllIn('#resultpath', result).map(path => {
            if(path.childElementCount < 10){
                loadMore.style.display = 'none';
            }else{
                loadMore.style.display = 'block';
            }
        });

        on('keypress', function (evt) {
            let code = evt.keyCode || evt.which;

            if(code === 13) {
                evt.preventDefault();
                initSearch(container, loading, searchInput);
            }

        }, searchInput);

        on('click', function () {
            initSearch(container, loading, searchInput);
        }, submitButton);


        on('click', function () {
            removeClass('hidden', loading);

            currentPage = currentPage + 1;

            let keyword = searchInput.value;

            let pendingRequest = fetch('/cse__search__cse', {
                method: 'GET',
                body: {
                    searchpage: currentPage,
                    keyword: keyword,
                    language: _config.lang,
                    json: 1
                }
            });

            pendingRequest.then((result) => {
                return result.clone().json()
            }).then((data) => {
                addClass('hidden', loading);

                let result = findIn('.js-search__result', container);
                let html = document.createElement('div');
                html.innerHTML = data.data;
                result.append(html);

                let toCheck = findAllIn('a, p', result);
                toCheck.forEach((item) => {
                    item.innerHTML = highlight(item.textContent, keyword);
                });

                findAllIn('#resultpath', result).map(path => {
                    if(path.childElementCount < 10){
                        loadMore.style.display = 'none';
                    }else{
                        loadMore.style.display = 'block';
                    }
                });
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                }
            });
        }, loadMore);

    });
}

function initSearch(container, loading, input) {
    let keyword = input.value;

    removeClass('hidden', loading);

    let pendingRequest = fetch('/cse__search__cse', {
        method: 'GET',
        body: {
            searchpage: 1,
            keyword: keyword,
            language: _config.lang,
            json: 1
        }
    });

    pendingRequest.then((result) => {
        return result.clone().json()
    }).then((data) => {
        addClass('hidden', loading);

        dataLayer.push({
            'event': 'gaTriggerEvent',
            'gaEventCategory': 'Search',
            'gaEventAction': 'search-event',
            'gaEventLabel': keyword
        });

        if(data.data !== "" && data.data.length > 10){
            let result = findIn('.js-search__result', container);
            result.innerHTML = data.data;

            let toCheck = findAllIn('a, p', result);
            if (keyword !== ''){
                toCheck.forEach((item) => {
                    item.innerHTML = highlight(item.textContent, keyword);
                });
            }

            findAllIn('#resultpath', result).map(path => {
                if(path.childElementCount < 10){
                    findIn('.js-search__load-more', container).style.display = 'none';
                }else{
                    findIn('.js-search__load-more', container).style.display = 'block';
                }
            });

            removeClass('hidden', findIn('.js-search__load-more', container));
        } else {
            findIn('.js-search__result').innerHTML = data.data;
            addClass('hidden', findIn('.js-search__load-more', container));
        }

        let link = new URL(window.location.href);
        let param = new URLSearchParams();
        param.append('keyword', keyword);
        link = addSearchParamsToUrl(link, param);
        window.history.replaceState(null, document.title, link);

    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}

function highlight(content, search) {
    let regexp = new RegExp("(\\b" + search + "\\b)", "gim");
    let newContent = content.replace(/(<span>|<\/span>)/igm, "");
    newContent = newContent.replace(regexp, "<span class='highlight'>$1</span>");

    return newContent;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}
