"use strict";
import {on, closest, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-overlay__toggle', function (toggleButton) {
        on('click', function (evt) {
            evt.preventDefault();

            let overlay = closest('.js-overlay', toggleButton);

            if(!hasClass('is-open', overlay)) {
                addClass('is-open', overlay);

                window.addEventListener("click", function _listener(evt) {
                    if (!closest('.js-overlay', toggleButton).contains(evt.target)) {
                        removeClass('is-open', overlay);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);
            } else {
                removeClass('is-open', overlay);
            }
        }, toggleButton);
    });
}