"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils';
import Tab from 'bootstrap/js/dist/tab';

export function init () {
    onFind('.js-multi-tab', function (container) {
        let masterTabs = findAllIn('.js-multi-tab__master', container);
        let slaveTabs = findAllIn('.js-multi-tab__slave', container);

        masterTabs.forEach(masterTab => {
            let slaveId = getPrefixedDataSet('multi-tab', masterTab);
            on('show.bs.tab', (e) => {
                if(slaveId){
                    slaveTabs.forEach(slaveTab => {
                        if(slaveTab.getAttribute('href').substring(1) === slaveId.slave){
                            let instance = Tab.getOrCreateInstance(slaveTab);
                            instance.show();
                        }
                    });
                }
            }, masterTab);
        });
    });
}
