"use strict";
import {on, find, findAllIn, findIn, empty, closest, hasClass, addClass, removeClass} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import fetch from '@elements/fetch';

let consoleItem,
    lastScrollTop,
    offset,
    didScroll,
    delta = 1;

export function init () {
    onFind('.js-ajaxInclude-console', function (consoleContainer) {
        if (matchMedia(consoleContainer.getAttribute('data-media')).matches) {
            let pendingRequest = fetch(consoleContainer.getAttribute('data-replace'), {
                method: 'get',
                body: {
                    json: 1,
                }
            });

            pendingRequest.then((result) => {
                return result.clone().json()
            }).then((result) => {
                if (result.success) {
                    consoleContainer.innerHTML = result.html;
                    initInScope(consoleContainer);

                    //commented out because console slideout was removed for static links
                    // initConsole(consoleContainer);

                    if(matchMedia('(min-width: 768px)').matches) {
                        if(matchMedia('(max-width: 992px)').matches) {
                            offset = closest('.console-placeholder', consoleContainer).getBoundingClientRect().top + window.scrollY;
                        }else {
                            offset = closest('.console-placeholder', consoleContainer).getBoundingClientRect().top + window.scrollY - 75;
                        }

                        consoleItem = findIn('.js-affix-console', consoleContainer);

                        if(matchMedia('(min-width: 768px)').matches) {
                            if (window.pageYOffset >= offset) {
                                addClass('affix', consoleItem);
                            }

                            on('scroll', function () {
                                didScroll = true;
                            }, window)

                            requestAnimationFrame(function animationFrameHandler() {
                                if (didScroll) {
                                    scrollHandler();

                                    didScroll = false;
                                }
                                requestAnimationFrame(animationFrameHandler);
                            });
                        }

                        if(document.readyState === 'complete'){
                            removeClass('hidden', findIn('.console', consoleContainer))
                        }else {
                            on('load', function () {
                                removeClass('hidden', findIn('.console', consoleContainer))
                            }, window);
                        }
                    }
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                }
            });
        }
    });
}

function scrollHandler() {
    let scrollTopPosition = window.pageYOffset;

    if (Math.abs(lastScrollTop - scrollTopPosition) <= delta)
        return;

    if (lastScrollTop > scrollTopPosition) {
        /*scroll up*/
        if(scrollTopPosition < offset) {
            removeClass('affix', consoleItem);
        }

    } else if (lastScrollTop < scrollTopPosition) {
        /*scroll down*/
        if(scrollTopPosition >= offset) {
            addClass('affix', consoleItem);
        }
    }

    lastScrollTop = scrollTopPosition;
}

// function initConsole($container) {
//     let toggleButtons = findAllIn('[data-toggle-group=console]', $container);
//
//     toggleButtons.map((button) => {
//         on('click', function (evt) {
//             evt.preventDefault();
//
//             let Console = closest('.js-affix-console', button),
//                 target = button.getAttribute('data-toggle-item');
//
//             if(!hasClass('affix', Console)){
//                 window.scrollTo({
//                     top: Console.getBoundingClientRect().top + window.scrollY - 70,
//                     behavior: 'smooth'
//                 });
//             }
//
//             if(hasClass('is-active', button)){
//                 removeClass('is-active', button);
//                 removeClass('is-open', Console);
//
//                 removeClass('is-active', find(target));
//                 removeClass('console-is-open', find('html'))
//
//             } else {
//                 toggleButtons.map((item) => {
//                    removeClass('is-active', item);
//                 });
//
//                 findAllIn('.console--detail__item', $container).map((item) => {
//                     removeClass('is-active', item);
//                 });
//
//                 addClass('is-active', button);
//                 addClass('is-open', Console);
//
//                 addClass('is-active', find(target));
//                 addClass('console-is-open', find('html'))
//             }
//         }, button)
//     });
//
//     let closeButtons = findAllIn('.js-console-close', $container);
//     closeButtons.map((button) => {
//         on('click', function () {
//             removeClass('is-active', closest('.console--detail__item', button));
//             toggleButtons.map((item) => {
//                 removeClass('is-active', item);
//             });
//
//             removeClass('is-open', closest('.js-affix-console', button));
//             removeClass('console-is-open', find('html'))
//         }, button)
//     });
// }
