"use strict";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {findIn, findAllIn, closest, find, on, trigger} from '@elements/dom-utils';

let myActiveTab = false;

export function init () {
    onFind('.js-slide-tabs', function (container) {
        findIn('li:first-child a', container).insertAdjacentHTML('beforeend', '<span class="slide-tabs__active hidden-xs js-slide-tabs__bar"></span>');

        findAllIn('[data-bs-toggle="tab"]', container).map((tabToggle) => {
            on('show.bs.tab', function () {
                let parent = closest('li', tabToggle),
                    posLeft = parent.getBoundingClientRect().left - container.getBoundingClientRect().left,
                    posWidth = tabToggle.getBoundingClientRect().width;

                findIn('.js-slide-tabs__bar', container).style.transform = 'translateX('+ posLeft +'px) translateZ(0)';
                findIn('.js-slide-tabs__bar', container).style.width = posWidth + 'px';
            }, tabToggle);
        });


        if(myActiveTab){
            let posLeft = find('#' + myActiveTab).parentNode.getBoundingClientRect().left - container.getBoundingClientRect().left;
            let posWidth = find('#' + myActiveTab).parentNode.getBoundingClientRect().width;

            findIn('.js-slide-tabs__bar', container).style.transform = 'translateX('+ posLeft +'px) translateZ(0)';
            findIn('.js-slide-tabs__bar', container).style.width = posWidth + 'px';
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams && urlParams.get('activeTab')) {
            let tabNavLink = findIn('#my' + urlParams.get('activeTab'), container);
            trigger('show.bs.tab', tabNavLink);
        }
    });
}