"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {getConfigValue} from '@elements/config-utils';
import Pristine from "../libs/pristine/pristine.js";
import {findIn, findAllIn, removeAttribute, setAttribute, closest} from '@elements/dom-utils';

export const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback parsley-errors-list'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

let pristineForms = [];

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options = defaultOptions) {
    if (getConfigValue('lang') === 'de' || getConfigValue('lang') === 'en' || getConfigValue('lang') === 'it') {
        Pristine.setLocale(getConfigValue('lang'))
    }else{
        Pristine.setLocale('en')
    }

    const pristine = new Pristine(form, options, false);

    form.addEventListener('submit', function (e) {
        let inputs = findAllIn('input:not([type="hidden"]), select', form);

        inputs = inputs.filter((input, index) => {
            return !closest('.js-form-validation__optional-item:not(.show)', input);
        });
        if (!pristine.validate(inputs)){
            e.preventDefault();
            e.stopImmediatePropagation();

            //focus on first error
            if(findIn('.form-group.has-error', form)){
                let firstError = findIn('.form-group.has-error', form);
                console.log('first error', firstError);
                findIn('input, select', firstError).focus();
            }
        }
    });

    let submitButton = findIn('.js-form-validation__btn', form);
    if (submitButton) {
        form.addEventListener('change', function (e) {
            setButtonState(pristine, submitButton)
        });

        setButtonState(pristine, submitButton)
    }

    pristineForms.push(pristine);
    return pristine;
}

function setButtonState(pristine, submitButton) {
    if (!pristine.validate()){
        setAttribute('disabled', true, submitButton);
    } else {
        removeAttribute('disabled', submitButton);
    }
}

export function updatePristine(form) {
    let pristine = pristineForms.filter((object) => {
        return object.form === form;
    });

    pristine[0].reset(); // resets errors
    pristine[0].destroy(); // clean up

    createFormValidation(form)
}
