"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, find, on, closest, is,trigger} from '@elements/dom-utils';
import Tab from 'bootstrap/js/dist/tab';

export function init () {
    onFind('.js-tab-toggle', function (element) {
        on('click', function (evt) {
            evt.preventDefault();
            let currentTab = closest('.tab-pane',element);
            let tabToggleContainer = closest('.js-tab-toggle-container',element);
            let tabPanes = findAllIn('.tab-pane',tabToggleContainer);
            let indexOfShownTabPane;

            if (element.getAttribute('data-toggle') === 'prev') {
                indexOfShownTabPane = tabPanes[tabPanes.indexOf(currentTab)-1];
                if (tabPanes.indexOf(currentTab) === 0){
                    indexOfShownTabPane = tabPanes[tabPanes.length-1];
                }
                new Tab(find('a[href="#'+ indexOfShownTabPane.getAttribute('id') +'"]')).show();
            } else {
                if (is(':last-child', currentTab)) {
                    indexOfShownTabPane = tabPanes[0];
                    new Tab(find('a[href="#'+ indexOfShownTabPane.getAttribute('id') +'"]')).show();
                } else {
                    indexOfShownTabPane = tabPanes[tabPanes.indexOf(currentTab)+1];
                    new Tab(find('a[href="#'+ indexOfShownTabPane.getAttribute('id') +'"]')).show();
                }
            }
        }, element);
    });
}
