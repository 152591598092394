"use strict";
import {onFind} from "./elements-packages/init-modules-in-scope";
import {addClass, find, hasClass, removeClass, on} from '@elements/dom-utils';

export function init () {
    if(matchMedia('(max-width: 767px)').matches) {
        let mobileNavPlaceholder = find('#mobile-nav-placeholder'),
            mobileNav = find('#main-nav').cloneNode(true);

        //todo anpassen falls die Navi umgebaut wird
        mobileNavPlaceholder.replaceWith(mobileNav);

        onFind('.js-navbar-toggle', function (navToggle) {
            on('click', function (evt) {
                evt.preventDefault();

                if(!hasClass('is-open', find('.mobile-nav'))){
                    addClass('is-open', find('.mobile-nav'))
                    addClass('is-open', navToggle)
                    find('.mobile-nav').focus();
                }else {
                    removeClass('is-open', navToggle)
                    removeClass('is-open', find('.mobile-nav'))
                }
            }, navToggle);
        });

    }
}





// export default function () {
//     if(matchMedia('(max-width: 767px)').matches) {
//         var mobileNavPlaceholder = $('#mobile-nav-placeholder'),
//             mobileNav = $('#main-nav').clone();
//
//         mobileNavPlaceholder.replaceWith(mobileNav);
//
//         $('.js-navbar-toggle').on('click', function () {
//             if(!$('.mobile-nav').hasClass('is-open')){
//                 $('.mobile-nav').addClass('is-open');
//                 $(this).addClass('is-open');
//             }else {
//                 $('.mobile-nav').removeClass('is-open');
//                 $(this).removeClass('is-open');
//             }
//         }).on('click', function (evt) {
//             evt.preventDefault();
//         });
//
//         mobileNav.find('.js-toggle-subnav').on('click', function(evt){
//             evt.preventDefault();
//             $(this).toggleClass('is-open');
//             $(this).closest('li').toggleClass('is-open');
//         });
//     }
// }
